import Swiper, {
    Navigation,
    Pagination,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectCoverflow,
    EffectFlip
} from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, EffectCube, EffectCoverflow, EffectFlip]);

(() => {

    // スライドの高さを揃える
    let maxHeight = 0;
    window.onload = () => {
        const slides = document.querySelectorAll('.swiper-slide');

        slides.forEach(slide => {
            const slideWrap = slide.querySelector('.swiper-slide-wrap');

            const wrapHeight = slideWrap.clientHeight;

            if (wrapHeight > maxHeight) {
                maxHeight = wrapHeight;
            }
        });

        slides.forEach(slide => {
            slide.style.height = `${maxHeight}px`;
        });
    }



    // Swiperのオプション
    let options = {
        'speed': 3000,
        'delay': 3000,
        'effect': 'slide',
        'slidesPerView': 1,
        'spaceBetween': 0,
    };


    if (document.querySelector('.js_target_swiperOptions')) {
        const swiperOptions = document.querySelector('.js_target_swiperOptions');
        options.delay = Number(swiperOptions.getAttribute('data-speed'));
        options.speed = Number(swiperOptions.getAttribute('data-delay'));
        options.effect = swiperOptions.getAttribute('data-effect');
        options.slidesPerView = swiperOptions.getAttribute('data-slidesPerView');
        options.spaceBetween = Number(swiperOptions.getAttribute('data-spaceBetween'));
    }


    var mySwiper = new Swiper('.swiper-container', {
        slidesPerView: options.slidesPerView, // 一度に表示する枚数
        spaceBetween: options.spaceBetween, // スライド間の距離
        centeredSlides: true, // アクティブなスライドを中央にする
        effect: options.effect,
        loop: true,
        speed: options.speed,
        autoplay: {
            delay: options.delay,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            slideChange: function () {
                // すべての動画を一時停止
                const videos = document.querySelectorAll('.video');
                videos.forEach(video => video.pause());

                // 現在のスライドに対応する動画を再生
                const currentSlide = this.slides[this.activeIndex];
                const video = currentSlide.querySelector('.video');

                if (video) {
                    // 動画の再生位置を調整 (ここでは0から開始)
                    this.autoplay.stop();
                    video.currentTime = 0;
                    video.play();

                    video.addEventListener('ended', function () {
                        // ここに動画終了後に実行したいコードを追加
                        mySwiper.autoplay.start();
                    });
                }
            }
        }
    });
})();
