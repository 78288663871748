(() => {
    const form01 = document.querySelector('#form01');
    let submitBtn01 = false;

    if(form01){
        let submitBtn01 = form01.querySelector('.submitBtn');

        submitBtn01.addEventListener('click', () => {
            insertPosition();
            form01.submit();
        })
    }

    const form02 = document.querySelector('#form02');
    let submitBtn02 = false;

    if(form02){
        let submitBtn02 = form02.querySelector('.submitBtn');

        submitBtn02.addEventListener('click', () => {
            insertPosition();
            form02.submit();
        })
    }

    const form03 = document.querySelector('#form03');
    let submitBtn03 = '';

    if(form03){
        let submitBtn03 = form03.querySelector('.submitBtn');

        submitBtn03.addEventListener('click', () => {
            insertPosition();
            form03.submit();
        })
    }


    // 送信ボタンを押したときに#searchResults要素の位置を取得する
    function insertPosition() {
        // 要素を取得
        const positionInputs = document.querySelectorAll('input[name="position"]');
        const targetElement = document.getElementById('searchResults');

        // 要素の位置を取得
        const position = targetElement.getBoundingClientRect().top;

        // 位置をvalue属性に挿入
        positionInputs.forEach(positionInput => {
            positionInput.value = position;
        });
    }
})();
