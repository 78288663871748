
(() => {

    // 「管理画面 > 外観 > メニュー」から追加された項目をすべて取得
    const menuItemsClass = document.querySelectorAll('.menu-item');

    // 取得した要素の中から、別タブ表示をさせたい項目のIDを配列で管理
    const itemIdLists = ['menu-item-1759','menu-item-1760'];


    // 上記の配列に含まれる項目のみ別タブ表示にする処理
    menuItemsClass.forEach( menuItem => {
        const elementId = menuItem.getAttribute('id');

        const result = itemIdLists.includes(elementId)

        if(result){
            const linkTag = menuItem.querySelector('a');
            linkTag.setAttribute('target','_blank');
            linkTag.setAttribute('rel','noopener noreferrer');
        }
    });

})();
