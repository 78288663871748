
(() => {

    const modalTargets = document.querySelectorAll('.modalTarget');
    const bodyElement = document.querySelector('body');

    modalTargets.forEach(modalTarget => {

        //要素を取得
        const modal = modalTarget.querySelector('.js-modal'),
            open = modalTarget.querySelector('.js-modal-open'),
            close = modalTarget.querySelector('.js-modal-close');

        //「開くボタン」をクリックしてモーダルを開く
        function modalOpen() {
            modal.classList.add('is-active');
            bodyElement.style.overflowY = "hidden";
        }
        open.addEventListener('click', modalOpen);

        //「閉じるボタン」をクリックしてモーダルを閉じる
        function modalClose() {
            modal.classList.remove('is-active');
            bodyElement.style.overflowY = "scroll";
        }
        close.addEventListener('click', modalClose);

        //「モーダルの外側」をクリックしてモーダルを閉じる
        function modalOut(e) {
            if (e.target == modal) {
                modal.classList.remove('is-active');
                bodyElement.style.overflowY = "scroll";
            }
        }
        addEventListener('click', modalOut);
    });

})();
